<template>
  <section class="pt-20 md:pt-32 pb-20 l-width">
    <div class="text-center pb-10 md:pb-20">
      <h3 class="text-primary-shades font-medium tracking-wide">
        INSIGHTS, IDEAS, AND INSPIRATION
      </h3>
      <h2
        class="text-3xl md:text-4xl lg:text-6xl py-4 md:py-8 font-bold text-accent"
      >
        Exploring Our Blog
      </h2>
      <p
        class="max-w-[70%] mx-auto tracking-wide text-base md:text-lg lg:text-xl"
      >
        Welcome to our blog, your gateway to all things coworking, remote work,
        productivity hacks, and more. Join us as we explore the ins and outs of
        the modern workspace landscape.
      </p>
    </div>

    <div
      class="justiy-center grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 2xl:gap-20"
    >
      <!-- blog 1 -->
      <div
        class="bg-white shadow-md border border-accent-tint rounded-lg lg:max-w-sm dark:border-bg-accent-tint"
      >
        <a
          href="https://medium.com/@faith_55729/elevate-your-workspace-experience-unlock-the-potential-of-your-office-72a2744eb855"
          target="_blank"
        >
          <img
            class="rounded-t-lg max-h-[300px] w-full"
            src="https://res.cloudinary.com/daeqws8jv/image/upload/v1720387670/23_jaipbc.jpg"
            alt="blog 1"
          />
        </a>
        <div class="p-5">
          <a href="#">
            <h5 class="text-gray-900 font-bold text-2xl tracking-tight mb-2">
              Elevate Your Workspace Experience
            </h5>
          </a>
          <p class="font-normal text-gray-700 mb-3 dark:text-gray-400">
            In today’s dynamic business landscape, where innovation and
            collaboration are the keys to success, having the right workspace
            can make all the difference...
          </p>
          <a
            href="https://medium.com/@faith_55729/elevate-your-workspace-experience-unlock-the-potential-of-your-office-72a2744eb855"
            target="_blank"
            class="text-white bg-primary-shades hover:bg-primary font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center dark:bg-primary-shades dark:hover:bg-primary"
          >
            Read more
            <svg
              class="-mr-1 ml-2 h-4 w-4"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </a>
        </div>
      </div>
      <!-- blog 2 -->
      <div
        class="bg-white shadow-md border border-accent-tint rounded-lg lg:max-w-sm dark:border-bg-accent-tint"
      >
        <a href="https://medium.com/@faith_55729" target="_blank">
          <img
            class="rounded-t-lg max-h-[300px] w-full"
            src="https://res.cloudinary.com/daeqws8jv/image/upload/v1720643533/conference_iif3qe.jpg"
            alt="blog 2"
          />
        </a>
        <div class="p-5">
          <a href="#">
            <h5 class="text-gray-900 font-bold text-2xl tracking-tight mb-2">
              Best Co-Working space in lagos - 2024
            </h5>
          </a>
          <p class="font-normal text-gray-700 mb-3 dark:text-gray-400">
            In today’s dynamic business landscape, where innovation and
            collaboration are the keys to success, having the right workspace
            can make all the difference...
          </p>
          <a
            href="https://medium.com/@faith_55729"
            target="_blank"
            class="text-white bg-primary-shades hover:bg-primary font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center dark:bg-primary-shades dark:hover:bg-primary"
          >
            Read more
            <svg
              class="-mr-1 ml-2 h-4 w-4"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </a>
        </div>
      </div>
      <!-- blog 3 -->
      <div
        class="bg-white shadow-md border border-accent-tint rounded-lg lg:max-w-sm dark:border-bg-accent-tint"
      >
        <a href="https://medium.com/@faith_55729" target="_blank">
          <img
            class="rounded-t-lg max-h-[300px] w-full"
            src="https://res.cloudinary.com/daeqws8jv/image/upload/v1720387669/21_ebhnpz.jpg"
            alt="blog 3"
          />
        </a>
        <div class="p-5">
          <a href="#">
            <h5 class="text-gray-900 font-bold text-2xl tracking-tight mb-2">
              Why should you consider working from a coworking space?
            </h5>
          </a>
          <p class="font-normal text-gray-700 mb-3 dark:text-gray-400">
            In today’s dynamic business landscape, where innovation and
            collaboration are the difference...
          </p>
          <a
            href="https://medium.com/@faith_55729"
            target="_blank"
            class="text-white bg-primary-shades hover:bg-primary font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center dark:bg-primary-shades dark:hover:bg-primary"
          >
            Read more
            <svg
              class="-mr-1 ml-2 h-4 w-4"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
